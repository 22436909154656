import React from "react"
import Title from "./Title"
import styled from "styled-components"


const Div = styled.div`
  background-color: #5a5a5a;
  color: white;
`
const Contact = () => {
  return (
    <Div id="contact">
      <div className="container">
        <Title title="Kontakt" />
        <p>mgr inż. Henryk Idzikowski</p>
        <p>ul. Karasiowa 48A</p>
        <p>40-748 Katowice</p>
        <p>tel. +48 606 378 106</p>
        <p>mail: techniart@wp.pl</p>
      </div>
    </Div>
  )
}
export default Contact
