import React from "react"
import Title from "./Title"
import styled from "styled-components"

const Paragraph = styled.p`
  text-indent: 1rem;
  
`
const About = () => {
  return (
    <div id="about" className="container">
      <Title title="O nas"/>
      <Paragraph>
        O nas Firmę założył mgr inż. Henryk Idzikowski w 1991 roku pod nazwa
        Techniart . Początek działalności to zaopatrzenie firm w narzędzia i
        artykuły ścierne. Od roku 2001 2018 nastepuje kolejny etap działalności
        firmy w zakresie konstruktorskiej w branży automotive. W tym okresie
        krystalizuje się obecna działalność firmy a w 2020roku na podwalinach
        firmy TECHNIart powstaje Modern Decor Studio, firma działająca w branży
        dekoratorskiej i aranżacyjnej wnętrz. Obecna Działalność firmy polega na
        tworzeniu form dekoracyjnych płaskich, (w postaci wzorów abstrakcyjnych,
        orientalnych, roślinnych lub geometrycznych możliwość wzorów jest
        nieograniczona) ze sklejki wycinanej laserowo, dodatkowo wykonany może
        być grawer także laserem oraz łączenie sklejki z laminatem, pleksi lub
        szkłem (dodatkowy wzór naklejany jest na dekoracje z drewna). Inną formą
        produkcji będą elementy dekoracyjne stojące, w postaci wzorów
        abstrakcyjnych, orientalnych, roślinnych lub geometrycznych możliwość
        wzorów jest nieograniczona, formy tu wykonywane to np. kwietniki, półki
        stojące lub wiszące dekorowane w/w sposób, inne formy to wieszaki
        naścienne i stojące o nie spotykanej formie co jest kwintesencją tego
        przedsięwzięcia a także nadstawki na stół zwiększające powierzchnie
        stołu, także o niespotykanym wzornictwie, ścianki działowe lub parawany
        oraz całe kompozycje naścienne współgrające z innymi formami
        dekoracyjnymi jak lampy wazony świeczniki. Tworzymy małe formy meblowe w
        postaci stolików kawowych oraz siedzisk do nich, wieszaków do
        przedpokojów a także różnego rodzaju stoliki i toaletki, półki do
        przedpokojów i sypialni. W tej technice będę wykonywał miedzy innymi
        ramki do luster , ramki do zdjąć a także wycinanie wszelkiego rodzaju
        zaproszeń kartek okolicznościowych z papieru i cienkich fornirów. Osobną
        tematyką jest wykonawstwo lamp wiszących , naściennych (kinkietów) ,
        nocnych i stojących. Jedną z form działalności będzie wykonywanie prac
        zleconych czyli usług dla prywatnych klientów i przedsiębiorców w
        zakresie aranżacji wnętrz myślimy tu o lokalach gastronomicznych czy
        małych pensjonatach. Współpracujemy z absolwentami i studentami
        ostatnich lat Akademii Sztuk Pieknych w Krakowie, Uniwersytetem
        Artystycznym w Poznaniu oraz Wydziałem Projektowym ASP w Katowicach.
      </Paragraph>
    </div>
  )
}
export default About
