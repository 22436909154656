import { observable, action } from 'mobx'

import { configure } from "mobx"
configure({ enforceActions: "always" })

class Store{
    @observable tekst = "pawel"
    tekst2 = "pawel"

    @action setTekst(txt: string){
        this.tekst = txt
    }
}

export const store = new Store()