import React from "react"
import { Link, navigate, graphql } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Test from "../components/test"

import { store } from "../Mobx/Store"
import { observer } from "mobx-react"
import About from "../components/About"
import Header from "../components/header"
import Gallery from "../components/Gallery"
import Contact from "../components/Contact"

const IndexPage = observer(({data} : any) => {
  console.log("store", store)
  console.log("IndexPage -> data", data)
  return (
    <Layout>
      <Header />
      <About />
      <Gallery />

      <Contact />
    </Layout>
  )
})

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default IndexPage
