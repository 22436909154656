import React from 'react'
import styled from 'styled-components';

const H1 = styled.h1`
position: relative;
    &:before{
        content: "";
        position: absolute;
        bottom: -0.5rem;
        width: 30%;
        height: .5rem;
        background-color: blue;
    }
`
const Title = ({title}: {title: string}) => {
    return (
        <H1>{title}</H1>
    )
}
export default Title;