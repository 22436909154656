import React from "react"
import main from "../images/main-background.jpg"
import { navigate } from "gatsby"
import styled from "styled-components"

const Image = styled.img`
  position: absolute;
  z-index: -1;
`
const Main = styled.div`
  height: 100vh;
`

const Nav = styled.nav`
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 1rem 0;
  border-top: .3rem solid grey;
  ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    li{
      font-size: 2rem;
      cursor: pointer;
    }
  }

`

const Header = () => {
  console.log("Header -> Header")
  return (
    <Main>
      <Image style={{ maxHeight: "100%" }} src={main} />
      <Nav>
        <ul>
          <li onClick={() => navigate("#about")}>O nas</li>
          <li onClick={() => navigate("#gallery")}>Galeria</li>
          <li onClick={() => navigate("#contact")}>Kontakt</li>
        </ul>
      </Nav>
    </Main>
  )
}
export default Header
