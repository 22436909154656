import React, { useEffect } from "react"
import Title from "./Title"
import Image from "./image"
import Img from "gatsby-image/withIEPolyfill"
import { graphql, useStaticQuery } from "gatsby"

const Gallery = () => {
  //   console.log("Gallery -> data2", data2)
  const data = useStaticQuery(graphql`
    query {
      decor: file(relativePath: { eq: "decor.JPG" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decor2: file(relativePath: { eq: "decor2.JPG" }) {
        childImageSharp {
          fluid(maxHeight: 100, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decor3: file(relativePath: { eq: "decor3.JPG" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  console.log("Gallery -> data", data)
  console.log("Gallery -> data", Object.entries(data))
  return (
    <div id="gallery" className="container">
      <Title title="Galeria" />
      <div style={{display: "flex"}}>

      {
        Object.values(data).map((v: any) => {
          console.log("v", v)
          // console.log("v", Object.fromEntries(v))
          return <Img objectFit="contain" style={{width: "100px", height: "100%"}} key={v.id} fluid={v.childImageSharp.fluid} />
        })
      }
      </div>
    </div>
  )
}

export default Gallery
